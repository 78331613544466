
import apiComm from '@/api/api/co.api.comm'

const States = {
  CREATE: 'create',
  PUBLISH: 'publish',
  DOWN: 'down'
}

const StateDescs = {
  [States.CREATE]: {
    text: '编辑',
    value: States.CREATE,
    icon: 'mdi-cloud-plus-outline',
    color: 'primary'
  },
  [States.PUBLISH]: {
    text: '开放',
    value: States.PUBLISH,
    icon: 'mdi-cloud-arrow-up-outline',
    color: 'green'
  },
  [States.DOWN]: {
    text: '隐藏',
    value: States.DOWN,
    icon: 'mdi-cloud-arrow-down-outline',
    color: 'blue-grey darken-1'
  },
}

const Enables = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
}

const BannerTypes = {
  HOME_MAJOR: 'home-major',
  HOME_MINOR: 'home-minor',
  HOME_RECOMMEND: 'home-recommand',
  HOME_ACTIVITY: 'home-activity',
  HOME_JOIN: 'home-join',
  HOT_MAJOR: 'hot-major',
  ACTIVITY_MAJOR: 'activity-major',
  CIRCLE_MAJOR: 'circle-major',
  ABOUT_MAJOR: 'about-major',
  MY_MAJOR: 'my-major',
  LOGIN_MAJOR: 'login-major',
  LOGIN_MINOR: 'login-minor',
  LOGIN_ASSIST: 'login-assist',
  FOOT_MAJOR: 'foot-major'
}

const ContentTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
  ARTICLE: 'article',
  ACTIVITY: 'activity',
  POUND: 'pound'
}

const Scopes = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  FANS: 'fans',
  MUTUAL: 'mutual'
}

const ScopeDescs = {
  [Scopes.PUBLIC]: {
    icon: 'mdi-eye-outline',
    text: '公开',
    value: Scopes.PUBLIC,
    effect: 'dark',
    type: 'red',
  },
  [Scopes.PRIVATE]: {
    icon: 'mdi-eye-lock-outline',
    text: '仅自己可见',
    value: Scopes.PRIVATE,
    effect: 'dark',
    type: 'black',
  },
  [Scopes.FANS]: {
    icon: 'mdi-eye-check-outline',
    text: '粉丝可见',
    value: Scopes.FANS,
    effect: 'dark',
    type: 'pink',
  },
  [Scopes.MUTUAL]: {
    icon: 'mdi-eye-plus-outline',
    text: '互关可见',
    value: Scopes.MUTUAL,
    effect: 'dark',
    type: 'blue',
  }
}

const ContentActions = {
  REMOVE: 'remove',
  COLLECT: 'collect',
  ATTENTION_PLUS: 'attention_add',
  ATTENTION_MINUS: 'attention_minus',
  SHIELD: 'shield'
}

const ContentActionDescs = {
  [ContentActions.REMOVE]: {
    icon: 'mdi-trash-can-outline',
    text: '删除',
    value: ContentActions.REMOVE,
    effect: 'dark',
    type: 'blue',
  },
  [ContentActions.COLLECT]: {
    icon: 'mdi-star-outline',
    text: '收藏',
    value: ContentActions.COLLECT,
    effect: 'dark',
    type: 'blue',
  },
  [ContentActions.ATTENTION_PLUS]: {
    icon: 'mdi-account-multiple-plus-outline',
    text: '加关注',
    value: ContentActions.ATTENTION_PLUS,
    effect: 'dark',
    type: 'blue',
  },
  [ContentActions.ATTENTION_MINUS]: {
    icon: 'mdi-account-multiple-minus-outline',
    text: '取消关注',
    value: ContentActions.ATTENTION_MINUS,
    effect: 'dark',
    type: 'blue',
  },
  [ContentActions.SHIELD]: {
    icon: 'mdi-eye-off-outline',
    text: '屏蔽此条',
    value: ContentActions.SHIELD,
    effect: 'dark',
    type: 'blue',
  }
}

const MessageTypes = {
  LIKE: 'likes',
  COMMENT: 'comment',
  ATTENTION: 'attention',
  CHAT: 'chat'
}

const Flags = {
  NO: 'no',
  YES: 'yes'
}

const ActivityStates = {
  CREATE: 'create',
  PUBLISH: 'publish',
  DOING: 'doing',
  FINISH: 'finish',
  DOWN: 'down'
}

const EnrollStates = {
  CREATED: 'created',
  PASS: 'pass',
  NOPASS: 'nopass',
  EXCELLENT: 'excellent'
}

const coComm = {
  States,
  StateDescs,
  Enables,
  BannerTypes,
  ContentTypes,
  Scopes,
  ScopeDescs,
  MessageTypes,
  ContentActions,
  ContentActionDescs,
  Flags,
  ActivityStates,
  EnrollStates,
  parseTags (strTags = '', needAll = false) {
    const srcArr = strTags.split(',')
    const dstArr = []

    for (const i in srcArr) {
      const src = apiComm.trim(srcArr[i] || '')
      if (src) {
        dstArr.push(src)
      }
    }
    const result = Array.from(new Set(dstArr))

    if (result.length > 0 && needAll) {
      result.unshift('全部')
    }

    return result
  },
  parseTitle (title) {
    let prefix = ''
    let suffix = ''
    let pound = ''
    let value = ''
    let pos = 0
    const temp = apiComm.trim(title)
    const strInd = temp.indexOf('#')
    if (strInd !== -1) {
      pos = strInd + 1
      if (strInd > 0) {
        prefix = temp.substring(0, strInd)
      }
    } else {
      pos = 0
    }
    const endInd = temp.indexOf('#', pos)
    if (endInd !== -1) {
      pound = temp.substring(strInd, endInd + 1)
      value = temp.substring(strInd + 1, endInd)
      suffix = temp.substring(endInd + 1)
    }
    let isPound = false
    if (strInd !== -1 && endInd !== -1) {
      isPound = true
    }
    const obj = {
      prefix,
      pound,
      suffix,
      value,
      isPound
    }
    // console.log('strInd: %o, pos: %o, endInd: %o, obj: %o', strInd, pos, endInd, obj)
    return obj
  },
  parseTitleEx (title) {
    let suffix = ''
    const indArr = []
    let ind = -1
    // let pos = -1
    const result = []

    const temp = apiComm.trim(title)
    ind = temp.indexOf('#')
    while (ind !== -1) {
      indArr.push(ind)
      ind = temp.indexOf('#', ind + 1)
    }

    // console.log('parseTitleEx indArr: %o', indArr)

    if (indArr.length > 1) {
      for (let i = 0; i < indArr.length; i += 2) {
        const ind1 = indArr[i]
        const ind2 = indArr[i + 1]
        // console.log('parseTitleEx ind1=%o, ind2=%o', ind1, ind2)

        if (i < 1) {
          if (ind1 > 0) {
            result.push({
              isPound: false,
              title: temp.substring(0, ind1)
            })
          } else {
            result.push({
              isPound: true,
              title: temp.substring(ind1, ind2 + 1)
            })
          }
        } else {
          ind = indArr[i - 1]
          if ((ind1 - ind) > 1) {
            result.push({
              isPound: false,
              title: temp.substring(ind + 1, ind1)
            })
          }

          result.push({
            isPound: true,
            title: temp.substring(ind1, ind2 + 1)
          })
        }
      }

      ind = indArr[indArr.length - 1]
      suffix = temp.substring(ind + 1)
      if (suffix) {
        result.push({
          isPound: false,
          title: suffix
        })
      }
    } else {
      result.push({
        isPound: false,
        title: temp
      })
    }

    // console.log('parseTitleEx result: %o', result)
    return result
  },
}

export default coComm
