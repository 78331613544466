
import api from '@/api/co.api'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import comm from '@/api/web/co.comm.js'

import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
dayjs.extend(relativeTime)

const imMsg = {
  getParams ({
    chatUserId = '',
    type = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'createTime',
    order = 'desc',
    reversed = false,
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: `${api.url.web_im_msg_record}/${chatUserId}`,
      data: {
        chatUserId,
        type,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      reversed,
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  create ({
    data,
    executing,
    executed
  }) {
    api.httpx.create({
      url: api.url.web_im_msg_create,
      data,
      executing,
      executed
    })
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_im_msg_delete,
      id,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const from = {
      userId: srcItem.fromId,
      username: srcItem.fromUsername,
      mobile: srcItem.fromMobile,
      avatar: srcItem.fromAvatar,
    }

    const to = {
      userId: srcItem.toId,
      username: srcItem.toUsername,
      mobile: srcItem.toMobile,
      avatar: srcItem.toAvatar
    }
    let content = null
    let hintTime = ''
    let shortTime = ''
    if (srcItem.type === comm.MessageTypes.CHAT) {
      content = srcItem.content
      hintTime = dayjs(srcItem.createTime).format('M月D日 HH:mm')
      shortTime = dayjs(srcItem.createTime).format('MM-DD HH:mm')
    } else {
      content = api.comm.jsonToObject(srcItem.content)
      const ct = parseInt(content.createTime, 10)
      const msgTime = dayjs(ct).format('YYYY-MM-DD HH:mm')

      hintTime = dayjs(msgTime).fromNow()
      shortTime = dayjs(msgTime).format('MM-DD HH:mm')

      content.hintTime = hintTime
      content.shortTime = shortTime
    }

    return {
      msgId: srcItem.msgId,
      type: srcItem.type,
      from,
      to,
      fromId: srcItem.fromId,
      toId: srcItem.toId,
      contentType: srcItem.contentType,
      content,
      state: srcItem.state,
      createTime: srcItem.createTime,
      updateTime: srcItem.updateTime,
      hintTime,
      shortTime,
      createBy: srcItem.createBy
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = imMsg.convertItem(item, false)
      if (params.reversed) {
        params.items.unshift(dstItem)
      } else {
        params.items.push(dstItem)
      }
    })
    return params.items.length
  }
}

export default imMsg
