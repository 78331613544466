
import api from '@/api/co.api'

const CancelUrls = {
  attention: api.url.web_behaviour_cancel_attention,
  collect: api.url.web_behaviour_cancel_collection,
  like: api.url.web_behaviour_cancel_like,
  circle: api.url.web_behaviour_cancel_subscribe
}

const coBehaviour = {
  attention ({
    attentionUserId,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: `${api.url.web_behaviour_attention}/${attentionUserId}`,
      data: {
        attentionUserId
      },
      executing,
      executed
    })
  },
  collect ({
    contentId,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: `${api.url.web_behaviour_collection}/${contentId}`,
      data: {
        contentId
      },
      executing,
      executed
    })
  },
  forward ({
    contentId,
    forwardedText,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: `${api.url.web_behaviour_forward}/${contentId}`,
      data: {
        contentId,
        forwardedText
      },
      executing,
      executed
    })
  },
  like ({
    contentId,
    executing = null,
    executed = null
  }) {
    api.httpx.pull({
      url: `${api.url.web_behaviour_like}/${contentId}`,
      data: {
        contentId
      },
      executing,
      executed
    })
  },
  cancel ({
    id,
    type,
    executing = null,
    executed = null
  }) {
    const url = CancelUrls[type] || ''
    api.httpx.pull({
      url: `${url}/${id}`,
      executing,
      executed
    })
  },
}

export default coBehaviour
