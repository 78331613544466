
import api from '@/api/co.api'
import dayjs from 'dayjs'

const coCollection = {
  getParams ({
    type = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_collection_list,
      data: {
        type,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsPersonal ({
    type = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_collection_list_personal,
      data: {
        type,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_collection_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_collection_delete,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.update({
      url: api.url.web_collection_update,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.create({
      url: api.url.web_collection_create,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const createTime = dayjs(srcItem.createTime).format('YYYY-MM-DD HH:mm')
    const updateTime = dayjs(srcItem.updateTime).format('YYYY-MM-DD HH:mm')

    const user = {
      userId: srcItem.authorId || '',
      username: srcItem.authorUsername,
      avatar: srcItem.authorAvatar,
      mobile: srcItem.authorMobile,
      signature: srcItem.authorSignature
    }
    let content = {}
    const forwardedFlag = srcItem.forwardedFlag || 'no'
    if (forwardedFlag === 'yes') {
      content = api.comm.jsonToObject(srcItem.content)
      content.collectNum = srcItem.sourceCollectNum || 0
      content.commentNum = srcItem.sourceCommentNum || 0
      content.forwardedNum = srcItem.sourceForwardedNum || 0
      content.hitNum = srcItem.sourceHitNum || 0
      content.likesNum = srcItem.sourceLikesNum || 0
    }

    return {
      collectionId: srcItem.collectionId,
      contentId: srcItem.contentId,
      title: srcItem.contentTitle,
      cover: srcItem.contentCover || srcItem.sourceCover,
      type: srcItem.contentType,
      collectNum: srcItem.collectNum || 0,
      commentNum: srcItem.commentNum || 0,
      forwardedNum: srcItem.forwardedNum || 0,
      hitNum: srcItem.hitNum || 0,
      likesNum: srcItem.likesNum || 0,
      forwardedFlag,
      content,
      createTime,
      updateTime,
      user
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coCollection.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coCollection
