
import api from '@/api/co.api'
import dayjs from 'dayjs'

const coActivity = {
  getParams ({
    type = '',
    state = '',
    doingDate = '',
    finishDate = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_activity_list,
      data: {
        type,
        state,
        doingDate,
        finishDate,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_activity_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  getCurrent ({
    id,
    executing = null,
    executed = null,
  }) {
    api.httpx.pull({
      url: `${api.url.web_activity_current}/${id}`,
      data: null,
      executing,
      executed,
    })
  },
  converContent (srcItem) {
    let result = {}
    if (srcItem.content) {
      if (srcItem.model === 'image') {
        result = api.comm.jsonToArray(srcItem.content)
      } else {
        result = srcItem.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
      }
    } else {
      result = ''
    }
    return result
  },
  getActivityHint (srcItem) {
    let hint = ''
    let valid = true
    const nowTime = dayjs()
    const validStart = dayjs(srcItem.validStart)
    const validEnd = dayjs(srcItem.validEnd)

    const nowTimeStr = nowTime.format('YYYY-MM-DD HH:mm')
    const validStartStr = validStart.format('YYYY-MM-DD HH:mm')
    const validEndStr = validEnd.format('YYYY-MM-DD HH:mm')

    let fromStart = validStart.diff(nowTime, 'day')
    let toEnd = validEnd.diff(nowTime, 'day')
    if (nowTimeStr < validStartStr) {
      valid = false
      fromStart = Math.abs(fromStart)
      if (fromStart > 0) {
        hint = `活动距离开始还有：${fromStart}天`
      } else {
        hint = `活动将在 ${validStartStr} 开始`
      }
    } else {
      if (nowTimeStr > validEndStr) {
        hint = '活动已截稿'
        valid = false
      } else {
        toEnd = Math.abs(toEnd)
        if (toEnd > 0) {
          hint = `活动距离截稿还有：${toEnd}天`
        } else {
          hint = `活动将在 ${validEndStr} 截稿`
        }
      }
    }

    // console.log({ hint, fromStart, toEnd, nowTimeStr, validStartStr, validEndStr })
    return {
      hint,
      valid,
      validStart: validStartStr,
      validEnd: validEndStr
    }
  },
  convertItem (srcItem, parseContent = true) {
    // const rules = api.comm.jsonToArray(srcItem.rules)
    const createTime = dayjs(srcItem.createTime).format('YYYY-MM-DD HH:mm')
    const updateTime = dayjs(srcItem.updateTime).format('YYYY-MM-DD HH:mm')

    const desc = coActivity.getActivityHint(srcItem)

    let content = null
    if (parseContent) {
      content = coActivity.converContent(srcItem)
    }
    const user = {
      userId: srcItem.createBy || '',
      username: srcItem.username,
      avatar: srcItem.avatar,
      mobile: srcItem.mobile,
      signature: srcItem.signature
    }

    return {
      activityId: srcItem.activityId,
      title: srcItem.title,
      talkTopic: srcItem.talkTopic || '',
      description: srcItem.description || '',
      summary: srcItem.summary || '',
      cover: srcItem.cover,
      model: srcItem.model,
      type: srcItem.type,
      submitType: srcItem.submitType,
      activityTags: srcItem.activityTags,
      articleTags: srcItem.articleTags || '',
      imageTags: srcItem.imageTags || '',
      videoTags: srcItem.videoTags || '',
      state: srcItem.state,
      likesNum: srcItem.likesNum || 0,
      collectNum: srcItem.collectNum || 0,
      joinNum: srcItem.joinNum || 0,
      hitNum: srcItem.hitNum || 0,
      content,
      accessScope: srcItem.accessScope,
      validStart: desc.validStart,
      validEnd: desc.validEnd,
      hint: desc.hint,
      valid: desc.valid,
      createTime,
      updateTime,
      createBy: srcItem.createBy || '',
      user
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coActivity.convertItem(item, true)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coActivity
