
import comm from '@/api/web/co.comm'
import activity from '@/api/web/co.activity'
import activityEnroll from '@/api/web/co.activity.enroll'
import albumImg from '@/api/web/co.album.img'
import album from '@/api/web/co.album'
import banner from '@/api/web/co.banner'
import category from '@/api/web/co.category'
import circle from '@/api/web/co.circle'
import circleSub from '@/api/web/co.circle.subscribe'
import collection from '@/api/web/co.collection'
import comment from '@/api/web/co.comment'
import content from '@/api/web/co.content'
import dict from '@/api/web/co.dict'
import fans from '@/api/web/co.fans'
import flags from '@/api/web/co.flags'
import guestbook from '@/api/web/co.guestbook'
import imMsg from '@/api/web/co.im.msg'
import imSession from '@/api/web/co.im.session'
import like from '@/api/web/co.like'
import reply from '@/api/web/co.reply'
import search from '@/api/web/co.search'
import topic from '@/api/web/co.topic'
import behaviour from '@/api/web/co.user.behaviour'
import wxmp from '@/api/web/co.wxmp'

const coWeb = {
  comm,
  activityEnroll,
  activity,
  albumImg,
  album,
  banner,
  category,
  circle,
  circleSub,
  collection,
  comment,
  content,
  dict,
  fans,
  flags,
  guestbook,
  imMsg,
  imSession,
  like,
  reply,
  search,
  topic,
  behaviour,
  wxmp
}

export default coWeb
