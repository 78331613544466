
import api from '@/api/co.api'
import app from '@/api/co.app'

import dayjs from 'dayjs'
// import relativeTime from 'dayjs/plugin/relativeTime'

import comm from '@/api/web/co.comm'

// dayjs.locale('zh-cn')
// dayjs.extend(relativeTime)

const coContent = {
  getParams ({
    userId = '',
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    accessScope = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'topFlag',
    order = 'desc',
    secSort = 'sort',
    secOrder = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list,
      data: {
        userId,
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        accessScope,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order,
        secSort,
        secOrder
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsHot ({
    userId = '',
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    accessScope = '',
    hotNum = '10',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'topFlag',
    order = 'desc',
    secSort = 'sort',
    secOrder = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_hot,
      data: {
        userId,
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        accessScope,
        hotNum,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order,
        secSort,
        secOrder
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsPersonal ({
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    accessScope = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_personal,
      data: {
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        accessScope,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsAttention ({
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_attention,
      data: {
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsAllCircle ({
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '12',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_all_circle,
      data: {
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsAttentionCircle ({
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '12',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_attention_circle,
      data: {
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getParamsSide ({
    contentId = '',
    type = '',
    talkTopic = '',
    state = '',
    categoryId = '',
    circleId = '',
    forwardedFlag = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '12',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_content_list_side,
      data: {
        contentId,
        type,
        talkTopic,
        state,
        categoryId,
        circleId,
        forwardedFlag,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_content_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_content_delete,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.update({
      url: api.url.web_content_update,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.create({
      url: api.url.web_content_create,
      data,
      executing,
      executed
    })
  },
  converContent (srcItem) {
    let result = null
    const artTypes = [
      comm.ContentTypes.ARTICLE,
    ]
    if (artTypes.indexOf(srcItem.type) !== -1 && srcItem.forwardedFlag === 'no') {
      if (srcItem.content) {
        result = srcItem.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
      } else {
        result = ''
      }
    } else {
      result = api.comm.jsonToObject(srcItem.content)
      if (srcItem.forwardedFlag === 'yes') {
        const ct = parseInt(result.createTime, 10)
        const ut = parseInt(result.updateTime, 10)
        result.createTime = dayjs(ct).format('YYYY-MM-DD HH:mm')
        result.updateTime = dayjs(ut).format('YYYY-MM-DD HH:mm')

        if (srcItem.parentId) {
          result.likesNum = srcItem.parentLikesNum || 0
          result.collectNum = srcItem.parentCollectNum || 0
          result.commentNum = srcItem.parentCommentNum || 0
          result.hitNum = srcItem.parentHitNum || 0
          result.forwardedNum = srcItem.parentForwardedNum || 0
        }

        const thumbs = api.comm.jsonToArray(result.thumbs)
        result.thumbs = thumbs
        if (!result.cover) {
          result.cover = thumbs[0] || 0
        }
      }
    }
    return result
  },
  convertItem (srcItem, parseContent = true) {
    const thumbs = api.comm.jsonToArray(srcItem.thumbs)
    const createTime = dayjs(srcItem.createTime).format('YYYY-MM-DD HH:mm')
    const updateTime = dayjs(srcItem.updateTime).format('YYYY-MM-DD HH:mm')
    const shortDate = dayjs(srcItem.createTime).format('MM-DD')

    let content = null
    if (parseContent) {
      content = coContent.converContent(srcItem)
    }
    const user = {
      userId: srcItem.createBy || '',
      username: srcItem.username,
      avatar: srcItem.avatar || app.pic.avatar_user,
      mobile: srcItem.mobile,
      signature: srcItem.signature
    }

    const title = srcItem.title.replace(/＃/g, '#')

    const dstItem = {
      contentId: srcItem.contentId,
      parentId: srcItem.parentId || '',
      categoryId: srcItem.categoryId || '',
      circleId: srcItem.circleId || '',
      title,
      cover: srcItem.cover || thumbs[0] || '',
      type: srcItem.type,
      state: srcItem.state,
      talkTopic: srcItem.talkTopic || '',
      likesNum: srcItem.likesNum || 0,
      collectNum: srcItem.collectNum || 0,
      commentNum: srcItem.commentNum || 0,
      hitNum: srcItem.hitNum || 0,
      forwardedNum: srcItem.forwardedNum || 0,
      forwardedFlag: srcItem.forwardedFlag || 'no',
      thumbs,
      content,
      accessScope: srcItem.accessScope,
      scopeDesc: comm.ScopeDescs[srcItem.accessScope] || {},
      createTime,
      updateTime,
      shortDate,
      createBy: srcItem.createBy || '',
      user,
      titles: comm.parseTitleEx(title)
    }

    if (!dstItem.cover) {
      dstItem.cover = content.cover
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coContent.convertItem(item, true)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coContent
