
import api from '@/api/co.api'

const coWxmp = {
  getQrcode ({
    executing = null,
    executed = null
  }) {
    const params = {
      url: api.url.web_wx_mp_qrcode,
      data: null,
      executing,
      executed,
    }
    api.httpx.pull(params)
  },
  login ({
    code,
    state,
    executing = null,
    executed = null
  }) {
    const params = {
      url: api.url.web_wx_mp_login,
      data: {
        code,
        state
      },
      executing,
      executed,
    }
    api.httpx.pull(params)
  },
  scan ({
    uuid,
    executing = null,
    executed = null
  }) {
    const params = {
      url: api.url.web_wx_mp_scan,
      data: {
        uuid
      },
      executing,
      executed,
    }
    api.httpx.push(params)
  },
  getTicket ({
    invalidTime = 300,
    executing = null,
    executed = null
  }) {
    const params = {
      url: api.url.web_wx_mp_get_ticket,
      data: {
        invalidTime
      },
      executing,
      executed,
    }
    api.httpx.pull(params)
  },
  checkQrcode ({
    qrcodeId,
    executing = null,
    executed = null
  }) {
    const params = {
      url: `${api.url.web_wx_mp_check_qrcode}/${qrcodeId}`,
      executing,
      executed,
    }
    api.httpx.pull(params)
  },
  expiredQrcode ({
    qrcodeId,
    executing = null,
    executed = null
  }) {
    const params = {
      url: `${api.url.web_wx_mp_expiredQrcode}/${qrcodeId}`,
      executing,
      executed,
    }
    api.httpx.pull(params)
  },
}

export default coWxmp
