
import api from '@/api/co.api'
import dayjs from 'dayjs'

import comm from '@/api/web/co.comm'

const coActivityEnroll = {
  getParams ({
    createBy = '',
    activityId = '',
    state = '',
    activityTag = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '9',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_activity_enroll_list,
      data: {
        createBy,
        activityId,
        state,
        activityTag,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getCurrent ({
    id,
    executing = null,
    executed = null,
  }) {
    api.httpx.pull({
      url: `${api.url.web_activity_enroll_current}/${id}`,
      data: null,
      executing,
      executed,
    })
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_activity_enroll_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_activity_enroll_delete,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.update({
      url: api.url.web_activity_enroll_update,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.create({
      url: api.url.web_activity_enroll_create,
      data,
      executing,
      executed
    })
  },
  converContent (srcItem) {
    let result = null
    const artTypes = [
      comm.ContentTypes.ARTICLE,
    ]
    if (artTypes.indexOf(srcItem.type) !== -1) {
      if (srcItem.content) {
        result = srcItem.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
      } else {
        result = ''
      }
    } else {
      result = api.comm.jsonToObject(srcItem.content)
      if (srcItem.forwardedFlag === 'yes') {
        const ct = parseInt(result.createTime, 10)
        const ut = parseInt(result.updateTime, 10)
        result.createTime = dayjs(ct).format('YYYY-MM-DD HH:mm')
        result.updateTime = dayjs(ut).format('YYYY-MM-DD HH:mm')

        if (srcItem.parentId) {
          result.likesNum = srcItem.parentLikesNum || 0
          result.collectNum = srcItem.parentCollectNum || 0
          result.commentNum = srcItem.parentCommentNum || 0
          result.hitNum = srcItem.parentHitNum || 0
          result.forwardedNum = srcItem.parentForwardedNum || 0
        }
      }
    }
    return result
  },
  convertItem (srcItem, parseContent = true) {
    const thumbs = api.comm.jsonToArray(srcItem.thumbs)
    const createTime = dayjs(srcItem.createTime).format('YYYY-MM-DD HH:mm')
    const updateTime = dayjs(srcItem.updateTime).format('YYYY-MM-DD HH:mm')

    let content = null
    if (parseContent) {
      content = coActivityEnroll.converContent(srcItem)
    }
    const user = {
      userId: srcItem.createBy || '',
      username: srcItem.username,
      avatar: srcItem.avatar,
      mobile: srcItem.mobile,
      signature: srcItem.signature
    }

    return {
      enrollId: srcItem.enrollId,
      activityId: srcItem.activityId,
      title: srcItem.title,
      talkTopic: srcItem.talkTopic || '',
      description: srcItem.description || '',
      summary: srcItem.summary || '',
      cover: srcItem.cover,
      type: srcItem.type,
      state: srcItem.state,
      likesNum: srcItem.likesNum || 0,
      collectNum: srcItem.collectNum || 0,
      hitNum: srcItem.hitNum || 0,
      forwardedNum: srcItem.forwardedNum || 0,
      forwardedFlag: srcItem.forwardedFlag || 'no',
      thumbs,
      content,
      accessScope: srcItem.accessScope,
      createTime,
      updateTime,
      createBy: srcItem.createBy || '',
      user
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coActivityEnroll.convertItem(item, true)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coActivityEnroll
