
import api from '@/api/co.api'

const coAlbumImg = {
  getParams ({
    albumId = '',
    enabled = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_album_img_list,
      data: {
        albumId,
        enabled,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_album_img_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_album_img_delete,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.update({
      url: api.url.web_album_img_update,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.create({
      url: api.url.web_album_img_create,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    return {
      ...srcItem
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coAlbumImg.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coAlbumImg
