
import api from '@/api/co.api'
// import lib from '@/api/co.lib'

const coDict = {
  getParams ({
    label = '',
    enabled = '',
    type = '',
    parentId = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'sort',
    order = 'asc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_dict_list,
      data: {
        label,
        enabled,
        type,
        parentId,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_dict_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  convertItem (srcItem) {
    const description = srcItem.description || ''
    let content = {}
    try {
      content = JSON.parse(description)
    } catch (error) {
      content = {
        value: description
      }
    }
    return {
      dictId: srcItem.dictId,
      value: srcItem.value,
      label: srcItem.label || '',
      type: srcItem.type,
      icon: srcItem.icon,
      description,
      content,
      sort: srcItem.sort,
      parentId: srcItem.parentId,
      createTime: srcItem.createTime
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coDict.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coDict
