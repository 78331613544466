
import api from '@/api/co.api'

const coBanner = {
  getParams ({
    type = '',
    state = '',
    search = '',
    searchType = '',
    currentPage = '1',
    pageSize = '6',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_banner_list,
      data: {
        type,
        state,
        search,
        searchType,
        currentPage,
        pageSize,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_banner_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  convertItem (srcItem) {
    const content = api.comm.jsonToObject(srcItem.content)
    return {
      bannerId: srcItem.bannerId,
      title: srcItem.title,
      cover: srcItem.cover,
      coverName: srcItem.coverName || '',
      coverAuthor: srcItem.coverAuthor || '',
      type: srcItem.type,
      summary: srcItem.summary || '',
      content,
      link: srcItem.link || '',
      state: srcItem.state,
      createTime: srcItem.createTime
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coBanner.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coBanner
