
import api from '@/api/co.api'

const coGuestbook = {
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.web_guestbook_detail}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.httpx.getDetail(params)
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.httpx.create({
      url: api.url.web_guestbook_create,
      data,
      executing,
      executed
    })
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.httpx.delete({
      url: api.url.web_guestbook_delete,
      id,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    return {
      ...srcItem
    }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coGuestbook.convertItem(item, false)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coGuestbook
