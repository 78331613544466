
import api from '@/api/co.api'
// import app from '@/api/co.app'

// import dayjs from 'dayjs'

const Types = {
  USER: 'user',
  CONTENT: 'content',
  ACTIVITY: 'activity'
}

const TypeDescs = {
  [Types.USER]: {
    text: '用户',
    value: Types.USER,
    color: 'blue'
  },
  [Types.CONTENT]: {
    text: '作品',
    value: Types.CONTENT,
    color: 'red'
  },
  [Types.ACTIVITY]: {
    text: '活动',
    value: Types.ACTIVITY,
    color: 'purple'
  },
}

const coSearch = {
  Types,
  TypeDescs,
  getParams ({
    title = '',
    type = '',
    currentPage = '1',
    pageSize = '10',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.web_search_list,
      data: {
        title,
        type,
        currentPage,
        pageSize,
        sort,
        order,
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  convertItem (srcItem, parseContent = true) {
    const typeDesc = TypeDescs[srcItem.type] || {}
    const dstItem = {
      ...srcItem,
      typeDesc,
      titles: []
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coSearch.convertItem(item, true)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coSearch
