
import appIcon from '@/api/app/co.app.icon'

const coFlags = {
  no: {
    forward: {
      color: 'blue-grey',
      icon: appIcon.forward
    },
    collect: {
      color: 'blue-grey',
      icon: appIcon.collect
    },
    like: {
      color: 'blue-grey',
      icon: appIcon.like
    }
  },
  yes: {
    forward: {
      color: 'primary',
      icon: appIcon.forward_fill
    },
    collect: {
      color: 'primary',
      icon: appIcon.collect_fill
    },
    like: {
      color: 'primary',
      icon: appIcon.like_fill
    }
  }
}

export default coFlags
